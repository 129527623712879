import React , {useEffect }from 'react';
import { loadReCaptcha } from 'react-recaptcha-v3';
import Layout from '../components/Layout';
import {devMode} from "../../config";

import Scroll from '../components/Scroll';

import favoriteThings from '../assets/images/favoriteThings.jpeg';
import chalkboardMath from '../assets/images/chalkboard-math.jpg';
import coins from '../assets/images/black-and-white-coins.jpg';
import config from '../../config';




const IndexPage = () => {




  return(
  <Layout>

    <section id="banner">
      <div className="inner">
        <h1>The Carlsbad Movers</h1>
        <p>San Diegos premier long distance movers</p>
        <p><a href="tel:7602590211"> Call 760-259-0211</a></p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="end">
              <a href="#moving-quote" className="button primary">
                Get quote
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            The Carlsbad Movers
            <br />
            is a local moving company serving the San Diego Area
          </h2>
          <p>
            Our core policy is to be honest and upfront.We treat your belongings
            with the same level of respect we would like to be given. We
            guarantee our movers will be prompt, polite, and professional at
            every step of the way.
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon fa-gem major style1">
              <span className="label">Lorem</span>
            </span>
          </li>
          <li>
            <span className="icon fa-heart major style2">
              <span className="label">Ipsum</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-thumbs-up major style3">
              <span className="label">Dolor</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
        <a href="/about">
          <img
            src={favoriteThings}
            alt="julie andrews the singer who sang favorite things"
          />
          </a>
        </div>
        <div className="content">
          <h2>
            You can count on us to carry your belongings and to keep your trust.
          </h2>
          <p>
            Because when you choose one of the <a href="/about">top rated cross country movers</a>
            you know with certainty that more than a few of your favorite things
            will arrive on time and in perfect condition.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
        <a href="/get-moving-quote">
          <img
            src={chalkboardMath}
            alt="a chalkboard with the incorrect math 1+1=3"

          />
          </a>
        </div>
        <div className="content">
          <h2><a href="/get-moving-quote">Moving quotes</a></h2>
          <p>
            As opposed to abstract quotes and guesses on cost we will provide
            you with <a href="/get-moving-quote">exact guaranteed pricing</a> so you know exactly what it will
            cost to move. Now it may not be the number you want to hear but it
            will be the real final price.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={coins} alt="" />
        </div>
        <div className="content">
          <h2>
            Cheap movers are just that
            <br />
            cheap!
          </h2>
          <p>
            And often times the cheap option ends up costing a lot in the end.
            Cheap companies will nickel and dime you to death because they
            believe clients are like paper plates.
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2><a href="/whatyouget">Our model is transparency</a></h2>
          <p>
            We strive to make sure that you know <a href="/whatyouget">exactly what to expect</a>. This is
            why all of our customers are happy to choose us and this is why
            everyone says The Carlsbad Movers are the best long distance moving
            company.Because when you ask we deliver.
          </p>
        </header>
        <ul className="features">
          <li className="icon solid fa-truck">
            <h3>Long distance moving</h3>
            <p>
              Moving to the other California? We deilver anywhere from Los
              Angeles to Eureka. Yes we even drive to Tahoe. Pick a city, if we
              can find an In n Out within a hundred miles we deliver.
            </p>
          </li>
          <li className="icon solid fa-truck">
            <h3>Cross country movers</h3>
            <p>
              Moving to Vegas? Done. Chicago? Sure. New York? Forget about it!
              You point us in the right direction and we get you there.
            </p>
          </li>
          <li className="icon solid fa-battery-full">
            <h3><a href="whatyouget">Full Service</a></h3>
            <p>
              <a href="/whatyouget">Loading and unloading.</a> <a href="/whatyouget">Disasembly and reassembly.</a> We bring a clean
              truck and a friendly crew and <a href="/fullpacking">do all the stuff</a> you don't want to
              so you can do what you really want.
            </p>
          </li>
          <li className="icon solid fa-trophy">
            <h3>Professional movers</h3>
            <p>
              As a trusted moving company we understand that you don’t just need
              day laborers to come and throw some boxes around. You deserve more
              than just a ragtag bunch of movers who show up on move day with
              muddy boots and gruff attitudes. You need a long distance mover
              who has an A team of all stars to walk you each step of the way.
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Planning</h3>
            <p>
              We will provide you with an exact <a href="get-moving-quote">step by step</a> plan so you can
              take a breath, relax and know with confidence this will all be
              great.
            </p>
          </li>
          <li className="icon fa-flag">
            <h3>Security</h3>
            <p>
              This is what you really want. And this is exactly what’s
              guaranteed when you pick up the phone and call. Now we can’t
              promise you that moving is easy, but you should know that with us
              it will be as good as moving from San Diego can be.
            </p>
          </li>
        </ul>

      </div>

    </section>
    <div id="end" />



  </Layout>
);}


export default IndexPage;
